import { Fragment, useState, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import _uniqueId from "lodash/uniqueId";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { FormattedMessage } from "react-intl";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import SaveIcon from "@material-ui/icons/Save";
import { FormikCreateDialog } from "../../components/dialogs";
import { Formik, useFormikContext } from "formik";
import { PageHeader } from "../../components";
import FormikTextField from "../../components/inputs/FormikTextField";
import Grid from "@material-ui/core/Grid";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import axios from "axios";
import { NotificationActions } from "../../actions";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { DeleteDialog } from "./components/DeleteDialog";
import Typography from "@material-ui/core/Typography";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import Image from "../../components/Image";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";

const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  bedCardContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(0, -1),
  },
  bedCard: {
    background: "#ffffff",
    width: "calc(12.5% - 10px)",
    minHeight: 100,
    minWidth: 165,
    border: "1px solid #e0e0e0",
    margin: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: "calc(25% - 10px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 10px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 10px)",
    },
  },
  bedCardHeader: {
    display: "flex",
    padding: theme.spacing(1),
    width: "100%",
    height: "calc(1rem + " + theme.spacing(2) + ")",
    fontSize: 14,
    borderBottom: "1px solid #e0e0e0",
    "& svg": {
      width: "1rem",
      height: "1rem",
      cursor: "move",
      marginRight: theme.spacing(1),
      verticalAlign: "middle",
    },
  },
  bedCardHeaderEditMode: {
    height: 70,
  },
  bedCardHeaderBedNo: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
  },
  bedCardContent: {
    padding: theme.spacing(1),
    paddingBottom: "0 !important",
  },
  maleBed: {
    backgroundColor: "#b1d1e4",
  },
  femaleBed: {
    backgroundColor: "#efbad6",
  },
  reservingBed: {
    backgroundColor: "#fef5d4",
  },
  profilePic: {
    width: 50,
    height: 50,
  },
  defaultIcon: {
    color: "#888888",
  },
  leaveIcon: {
    color: "yellow",
  },
  hospitalIcon: {
    color: "red",
  },
}));

const DragHandle = sortableHandle(() => <ControlCameraIcon />);

const SortableItem = sortableElement(
  ({
    classes,
    value,
    handleBedNo,
    handleBed,
    handleDeleteBedDialogOpen,
    clearNotification,
    handleDelete,
    setDeleteBed,
  }) => (
    <Formik
      initialValues={{
        bedNo: value.bedNo,
        id: value.id,
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        isSubmitting,
      }) => (
        <Fragment>
          <Card
            className={clsx(classes.bedCard, {
              [classes.maleBed]: value?.resident?.gender == "M",
              [classes.femaleBed]: value?.resident?.gender == "F",
              [classes.reservingBed]:
                value?.resident?.reservations?.[0]?.status === "RESERVING",
            })}
            elevation={0}
          >
            <CardHeader
              className={clsx(classes.bedCardHeader, {
                [classes.bedCardHeaderEditMode]: !value?.disabled,
              })}
              disableTypography={true}
              title={
                <span>
                  {!value.disabled ? (
                    <DragHandle className={classes.dragHandle} />
                  ) : null}
                  {!value.disabled ? (
                    <FormikTextField
                      label="床位編號"
                      name="bedNo"
                      id={values.id}
                      onBlur={(event) => handleBedNo(event, values.id)}
                    />
                  ) : (
                    <span className={classes.bedCardHeaderBedNo}>
                      {value.bedNo}
                    </span>
                  )}
                </span>
              }
            ></CardHeader>
            <CardContent className={classes.bedCardContent}>
              {value?.resident ? (
                value?.resident?.reservations?.[0]?.status === "RESERVING" ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>已訂床位</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item xs={4}>
                      <Avatar
                        className={classes.profilePic}
                        alt={
                          value.resident.lastnameTc + value.resident.firstnameTc
                        }
                      >
                        {value.resident.photoUrl && (
                          <Image
                            url={value.resident.photoUrl}
                            style={{
                              maxWidth: 60,
                              maxHeight: 60,
                            }}
                          />
                        )}
                      </Avatar>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={12} style={{ marginBottom: 15 }}>
                          <Typography>
                            姓名:{" "}
                            {value.disabled ? (
                              <Link
                                to={ROUTES.RESIDENT.replace(
                                  ":id",
                                  value.resident.id
                                )}
                              >
                                {value.resident.lastnameTc +
                                  value.resident.firstnameTc}
                              </Link>
                            ) : (
                              value.resident.lastnameTc +
                              value.resident.firstnameTc
                            )}
                          </Typography>
                        </Grid>
                        <Grid item align="right" xs={12}>
                          <Tooltip title={value.leave == true ? "外宿中" : ""}>
                            <HomeOutlinedIcon
                              className={clsx(classes.defaultIcon, {
                                [classes.leaveIcon]: value.leave == true,
                              })}
                            />
                          </Tooltip>
                          <Tooltip title={value.emergency ? "留院中" : ""}>
                            <LocalHospitalOutlinedIcon
                              className={clsx(classes.defaultIcon, {
                                [classes.hospitalIcon]: value.emergency == true,
                              })}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              ) : (
                !value.disabled && (
                  <Grid container>
                    <Grid item xs={12} align="right">
                      <Tooltip title="刪除">
                        <IconButton
                          color="default"
                          size="small"
                          onClick={() => {
                            clearNotification();
                            handleDeleteBedDialogOpen(true);
                            handleDelete(values.id);
                            handleBed(values.bedNo);
                            setDeleteBed(values);
                          }}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )
              )}
            </CardContent>
          </Card>
        </Fragment>
      )}
    </Formik>
  )
);
const SortableContainer = sortableContainer(({ className, children }) => {
  return <div className={className}>{children}</div>;
});

const CreateDialog = (props) => {
  const {
    open,
    onClose,
    onSave,
    entityModel,
    entity,
    initialValues,
    validationSchema,
    dataInputForm: DataInputForm,
    dialogId = _uniqueId("dialog"),
    ...rest
  } = props;

  return (
    <FormikCreateDialog
      open={open}
      entityModel={entityModel}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSave}
      onClose={onClose}
      maxWidth="xs"
    >
      {props.children}
    </FormikCreateDialog>
  );
};

const CreateForm = (props) => {
  const { entity, ...rest } = props;

  const { initialValues, values, resetForm } = useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
  }, []);

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <FormikTextField
              required
              label="編號開始"
              name="from"
              type="number"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormikTextField
              required
              label="編號結束"
              name="to"
              type="number"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormikTextField label="床位編號前綴" name="prefix" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const SingleCreateForm = (props) => {
  const { entity, ...rest } = props;

  const { initialValues, values, resetForm } = useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
  }, []);

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <FormikTextField required label="床位編號" name="bedNo" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default function BedManagementPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const classes = useStyles();
  const [beds, setBeds] = useState([]);
  const [createBedDialogOpen, setCreateBedDialogOpen] = useState(false);
  const [createSingleBedDialogOpen, setCreateSingleBedDialogOpen] =
    useState(false);
  const [deleteBedDialogOpen, setDeleteBedDialogOpen] = useState(false);
  const [editingModeActive, setEditingModeActive] = useState(false);
  const [currentBedNo, setCurrentBedNo] = useState("");
  const [currentBed, setCurrentBed] = useState("");
  const [deleteBed, setDeleteBed] = useState(null);

  const getEntities = () => {
    if (home.home) {
      axios.get("/api/homes/" + home.home.id + "/beds").then((response) => {
        let data = response.data;
        let items = [];

        data &&
          data.content &&
          data.content.forEach(function (item, index) {
            item.sort = index;
            item.disabled = !editingModeActive;
            items.push(item);
          });
        setBeds(items);
      });
    } else {
      setBeds([]);
    }
  };

  const handleEditingModeActive = (value) => {
    setEditingModeActive(value);

    beds.forEach(function (item, index) {
      beds[index].sort = index;
      beds[index].disabled = !value;
    });

    setBeds(beds);
  };

  const clearNotification = () => {
    dispatch(NotificationActions.clear());
  };

  const handleCreateBedDialogOpen = (value) => {
    setCreateBedDialogOpen(value);
  };

  const handleCreateSingleBedDialogOpen = (value) => {
    setCreateSingleBedDialogOpen(value);
  };

  const handleDeleteBedDialogOpen = (value) => {
    setDeleteBedDialogOpen(value);
  };

  const handleDelete = (value) => {
    setCurrentBedNo(value);
  };

  const handleBed = (value) => {
    setCurrentBed(value);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setBeds(arrayMove(beds, oldIndex, newIndex));
  };

  const onSaveOrder = () => {
    let bedSortList = [];

    beds.forEach((bed, index) => {
      bedSortList.push({ bedId: bed.id, sort: index });
    });

    axios
      .post("/api/beds/batchUpdateBedOrder", { bedSortList: bedSortList })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        getEntities();
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
      });
  };

  const onCreate = (values) => {
    handleCreateBedDialogOpen(false);

    axios
      .post("/api/homes/createHomeBed", {
        homeId: home.home.id,
        from: values.from,
        to: values.to,
        prefix: values.prefix,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已新增"));
        getEntities();
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
      });
  };

  const onCreateSingle = (values) => {
    handleCreateSingleBedDialogOpen(false);

    axios
      .post("/api/homes/createHomeBedSingle", {
        homeId: home.home.id,
        bedNo: values.bedNo,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已新增"));
        getEntities();
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
      });
  };

  const onDelete = (values, { setSubmitting }) => {
    if (currentBedNo !== "") {
      axios
        .delete("/api/beds/delete/" + currentBedNo)
        .then((response) => {
          dispatch(NotificationActions.success(null, "已刪除"));
          getEntities();
          setDeleteBedDialogOpen(false);
          setDeleteBed(null);
        })
        .catch((error) => {
          dispatch(NotificationActions.error(null, "錯誤"));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const handleBedNo = (event, value) => {
    axios
      .put("/api/beds/update/" + value, {
        bedNo: event.target.value,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        getEntities();
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
      });
  };

  useEffect(() => {
    getEntities();
    setEditingModeActive(false);
  }, [home]);

  return (
    <Fragment>
      <PageHeader title={"床位管理"}>
        <Button
          variant="contained"
          color="primary"
          className={classes.toolbarButton}
          startIcon={editingModeActive ? <LockOpenIcon /> : <LockIcon />}
          onClick={() => {
            handleEditingModeActive(!editingModeActive);
          }}
        >
          編輯模式
        </Button>
        {editingModeActive && (
          <Button
            variant="contained"
            color="primary"
            className={classes.toolbarButton}
            startIcon={<SaveIcon />}
            onClick={onSaveOrder}
          >
            儲存排序
          </Button>
        )}
        {editingModeActive && (
          <Button
            variant="contained"
            color="primary"
            className={classes.toolbarButton}
            startIcon={<LibraryAddIcon />}
            onClick={() => {
              clearNotification();
              handleCreateBedDialogOpen(true);
            }}
          >
            批量新增床位
          </Button>
        )}
        {editingModeActive && (
          <Button
            variant="contained"
            color="primary"
            className={classes.toolbarButton}
            startIcon={<AddCircleOutlinedIcon />}
            onClick={() => {
              clearNotification();
              handleCreateSingleBedDialogOpen(true);
            }}
          >
            新增單個床位
          </Button>
        )}
      </PageHeader>
      <SortableContainer
        axis={"xy"}
        className={classes.bedCardContainer}
        onSortEnd={onSortEnd}
        useDragHandle
      >
        {beds &&
          beds.map((bed, index) => {
            return (
              <SortableItem
                classes={classes}
                key={bed.id}
                disabled={!editingModeActive}
                index={index}
                value={bed}
                handleBedNo={handleBedNo}
                handleDeleteBedDialogOpen={handleDeleteBedDialogOpen}
                handleDelete={handleDelete}
                handleBed={handleBed}
                setDeleteBed={setDeleteBed}
                clearNotification={clearNotification}
              />
            );
          })}
      </SortableContainer>

      <CreateDialog
        open={createBedDialogOpen}
        entityModel="床位"
        initialValues={{
          to: "",
          from: "",
          prefix: "",
        }}
        validationSchema={Yup.object().shape({
          from: Yup.number().required(
            <FormattedMessage id="validation.message.required" />
          ),
          to: Yup.number().required(
            <FormattedMessage id="validation.message.required" />
          ),
        })}
        dataInputForm={Button}
        onClose={() => {
          setCreateBedDialogOpen(false);
        }}
        onSave={onCreate}
      >
        <CreateForm />
      </CreateDialog>

      <CreateDialog
        open={createSingleBedDialogOpen}
        entityModel="單個床位"
        initialValues={{
          bedNo: "",
        }}
        validationSchema={Yup.object().shape({
          bedNo: Yup.string().required(
            <FormattedMessage id="validation.message.required" />
          ),
        })}
        dataInputForm={Button}
        onClose={() => {
          setCreateSingleBedDialogOpen(false);
        }}
        onSave={onCreateSingle}
      >
        <SingleCreateForm />
      </CreateDialog>
      <DeleteDialog
        open={deleteBedDialogOpen}
        entityModel="床位"
        entity={deleteBed}
        onClose={() => {
          setDeleteBedDialogOpen(false);
        }}
        onSubmit={onDelete}
        currentBed={currentBed}
      ></DeleteDialog>
    </Fragment>
  );
}
